import { toDanishPriceFormat } from "@utils/formatting";
import classes from "./PriceTag.module.scss";

export default function PriceTag(props: {
	initialPrice: number,
	discountedPrice?: number,
	currencySuffix?: React.ReactNode | string,
	variant: "large" | "compact",
	prefixText?: string
}) {
	return <div className={classes.price}>
		<span style={{ fontSize: props.variant === "large" ? 40 : "inherit" }}>
			{props.prefixText &&
				<span style={{ fontWeight: 900 }}>
					{props.prefixText}
				</span>}
			{props.discountedPrice ?
				<del style={{ fontWeight: 100 }}>
					{toDanishPriceFormat(props.initialPrice)}
				</del>
				:
				<span style={{ fontWeight: 900 }}>
					{toDanishPriceFormat(props.initialPrice)}
				</span>}
			{props.discountedPrice ?
				<span style={{ fontWeight: 900, paddingLeft: 8 }}>
					{toDanishPriceFormat(props.discountedPrice)}
				</span>
				:
				null
			}
		</span>
		<span style={{
			paddingTop: 6,
			paddingLeft: 4,
			fontWeight: 'bold',
			fontSize: props.variant === "large" ? 16 : "inherit"
		}}>
			<span style={{ fontWeight: 100 }}>{props.currencySuffix ?? ""}</span>
		</span>
	</div>;
}